import {Button, Col, Form, Row, Table} from "reactstrap"
import React, {useContext} from "react"
import {ModalContext} from "../../../../contexts"
import moment from "moment/moment"
import {isEmpty} from "lodash"
import {changeRevisionStatus} from "../../../../api/customers"
import {toast} from "react-toastify"
import classnames from "classnames";

const ProfileRevisionsModal = ({profileRevisions, updateProfile}) => {
  const {closeModal} = useContext(ModalContext)

  const changeRevisionStatusHandler = (status, revision_id) => {
    changeRevisionStatus(status, revision_id).then(r => {
      if (r.success) {
        toast.success(r.message)
        // closeModal()
        window.document.querySelectorAll('details').forEach(i => i.open = false)
        updateProfile()
      } else {
        toast.error(r.message)
      }
    })
  }

  return (
    <>
      <Form>
        <Row>
          <div className="mb-2">
            {profileRevisions?.length ?
              Object.entries(profileRevisions).map(([key, revision], idx) => (
                <details key={idx}>
                  <summary>
                    {revision.type.capitalize()} revision #{revision.id},
                    date: {moment(revision.updated_at).format("YYYY-MM-DD")},
                    status:&nbsp;
                    <span
                      className={classnames('badge', {
                        'bg-warning': revision.status === 'pending',
                        'bg-secondary': revision.status === 'was applied',
                        'bg-danger': revision.status === 'rejected',
                      })}
                    >
                      {revision.status}
                    </span>
                  </summary>
                  <Col className="table-responsive d-none d-lg-block">
                    <Table className="table-striped align-middle">
                      <tbody>
                      {revision.data &&
                        Object.entries(revision.data).map(([key, val], idx) => {
                            if (!isEmpty(val)) {
                              return (
                                <tr key={idx}>
                                  <td>{key}:</td>
                                  <td>{val}</td>
                                </tr>
                              )
                            }
                          }
                        )
                      }
                      </tbody>
                    </Table>
                  </Col>
                  {revision.status === 'pending' &&
                    <Col>
                      <div className="mb-2">
                        <Button
                          type="button"
                          color="success"
                          onClick={() => changeRevisionStatusHandler('was applied', revision.id)}
                          className="btn me-3"
                        >
                          Approve
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => changeRevisionStatusHandler('rejected', revision.id)}
                          className="btn me-3"
                        >
                          Reject
                        </Button>
                      </div>
                    </Col>
                  }
                </details>
              )) :
              <p>No data</p>
            }
          </div>
        </Row>
        <Row>
          <div>
            <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export default ProfileRevisionsModal
